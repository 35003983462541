<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<!-- <a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item> -->
					
					<a-form-item label="品牌名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入品牌名称"/>
					</a-form-item>
					
					<a-form-item label="品牌状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择商品状态" style="width: 190px;">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['mall_setting_brand_add']" type="primary" @click="onAdd">添加品牌</a-button>
						<a-button v-permission="['mall_setting_brand_open']" :disabled="selectedRowKeys.length === 0" type="primary" ghost @click="onBatchDisabled(true)" style="margin-left: 20px;">批量启用</a-button>
						<a-button v-permission="['mall_setting_brand_disabled']" :disabled="selectedRowKeys.length === 0" type="primary" ghost @click="onBatchDisabled(false)" style="margin-left: 20px;">批量禁用</a-button>
						<a-button v-permission="['mall_setting_brand_delete']" :disabled="selectedRowKeys.length === 0" danger style="margin-left: 20px;" @click="onBatchDelete">批量删除</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }" >
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime)}}
						</template>
						<template v-if="column.key === 'imgUrl'">
							<a-image :src="record.logoUrl" style="width: 60px;"></a-image>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['mall_setting_brand_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['mall_setting_brand_disabled']" v-if="record.isDisabled === 0" @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['mall_setting_brand_open']" v-else @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['mall_setting_brand_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="showModal" title="添加商品品牌" width="750px" @ok="onSubmit">
				<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
					<!-- <a-form-item label="所属商城" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-select placeholder="请选择所属商城"></a-select>
					</a-form-item> -->
					
					<a-form-item label="品牌名称" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.title" placeholder="请输入品牌名称"/>
					</a-form-item>
					
					<a-form-item label="品牌描述" name="description">
						<a-textarea v-model:value="modelRef.description" placeholder="请输入品牌描述"></a-textarea>
					</a-form-item>
					
					<a-form-item name="logoUrl" label="图片" :rules="[{ required: true, message: '必填项不允许为空' }]">
					    <a-upload
					            v-model:file-list="fileList"
					            name="file"
					            list-type="picture-card"
					            class="avatar-uploader"
					            :show-upload-list="false"
					            action="/admin/ajaxUpload.do"
					            :before-upload="beforeUpload"
					            @change="handleChange"
					    >
					        <img v-if="modelRef.logoUrl" :src="modelRef.logoUrl" alt="avatar" style="width: 100px;height: 100px;"/>
					        <div v-else>
					            <div class="ant-upload-text">上传</div>
					        </div>
					    </a-upload>
					</a-form-item>
					
					<a-form-item label="排序号" name="sort" extra="数字越大，排序越前，如果同级，最新创建的优先级大" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getGoodsBrandList, getGoodsBrandDetail, saveGoodsBrand, updateGoodsBrand, deleteGoodsBrand,pullOnShelvesGoodsBrand , pullOffShelvesGoodsBrand, batchPullOnShelvesGoodsBrand, batchPullOffShelvesGoodsBrand, batchDeleteGoodsBrand } from '@/service/modules/mall.js';
	export default {
		components: {Icon },
		data() {
			return {
				loading: false,
				formState: {},
				organizationList: [],
				cinemaAllList: [],
				fileList: [],
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				showModal: false,
				isEdit: false,
				modelRef: {
					isDisabled: 0
				},
				list: [],
				columns: [{
					title: '品牌名称',
					dataIndex: 'title'
				}, {
					title: '品牌图片',
					key: 'imgUrl'
				}, {
					title: '品牌描述',
					dataIndex: 'description'
				}, {
					title: '排序号',
					dataIndex: 'sort'
				},{
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsBrandList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					let ret;
					this.loading = true;
					try {
						if (this.isEdit) {
							ret = await updateGoodsBrand(postData);
						} else {
							ret = await saveGoodsBrand(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteGoodsBrand({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功')
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该商品品牌吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							if (item.isDisabled) {
								ret = await pullOnShelvesGoodsBrand({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesGoodsBrand({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchDisabled(type) {
				this.$confirm({
					title: '提示',
					content: '确定批量' + (type ? '启用' : '禁用') + '该商品品牌吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							if (type) {
								ret = await batchPullOnShelvesGoodsBrand({
									ids: this.selectedRowKeys.join(',')
								})
							} else {
								ret = await batchPullOffShelvesGoodsBrand({
									ids: this.selectedRowKeys.join(',')
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.$message.success(( type ? '批量启用' : '批量禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchDelete() {
				this.$confirm({
					title: '提示',
					content: '确定批量删除吗?',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await batchDeleteGoodsBrand({
								ids: this.selectedRowKeys.join(',')
							});
							this.loading = false;
							if (ret.code === 200) {
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.$message.success('批量删除成功!');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						} 
					}
				})
			},
			onAdd() {
				this.isEdit = false;
				this.showModal = true;
				this.$nextTick(() => {
					this.$refs.addForm.resetFields();
				});
			},
			async onEdit(item) {
				this.loading = true;
				try {
					let ret = await getGoodsBrandDetail({
						id: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.isEdit = true;
						this.showModal = true;
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
 			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			beforeUpload(file) {
			    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			    if (!isJpgOrPng) {
			        this.$message.error('上传文件格式不正确');
			    }
			    const isLt1M = file.size / 1024 / 1024 < 1;
			    if (!isLt1M) {
			      this.$message.error('上传文件太大了');
			    }
			    return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
			     if (info.file.status === 'done') {
			        //console.log(info.file);
			        this.fileList = info.fileList;
			        const $fileList = [...this.fileList];
			        this.fileList = $fileList.map(file => {
			            if (file.response) {
							this.modelRef.logoUrl = file.response.data.imgUrl;
			                file.url = file.response.data.imgUrl;
			            }
			            return file;
			        });
			    }
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
